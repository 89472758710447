
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'clear-self cm_SearchPage' }, [_createElement('div', {
            'className': 'cm_FacetPanel',
            'key': '410'
        }, [this.WheelTireVehicleInfo(function () {
                function scopeWheelData1() {
                    var wheelData = this.data.WheelData;
                    return [wheelData ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_wheels-data',
                            'key': '15531'
                        }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment' }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment__label' }, 'Recommended Wheel Sizes')), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_axle_container' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_front-axle' }, wheelData.RearAxle ? _createElement('span', {
                            'className': 'cm_wheel-tire-vehicle-info_axle-title',
                            'key': '2032'
                        }, 'Front Sizes:') : null, wheelData.FrontAxle.Diameter ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_size-item',
                            'key': '2145'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Diameter:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Diameter)) : null, wheelData.FrontAxle.Width ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_size-item',
                            'key': '2471'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Width:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Width)) : null, wheelData.FrontAxle.Offset ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_size-item',
                            'key': '2788'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Offset:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Offset)) : null), wheelData.RearAxle ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_rear-axle',
                            'key': '3125'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_axle-title' }, 'Rear Sizes:'), wheelData.RearAxle.Diameter ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_size-item',
                            'key': '3302'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Diameter:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Diameter)) : null, wheelData.RearAxle.Width ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_size-item',
                            'key': '3626'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Width:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Width)) : null, wheelData.RearAxle.Offset ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_size-item',
                            'key': '3941'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Offset:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Offset)) : null) : null), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_attributes_container' }, wheelData.BoltPattern ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_bolt-pattern',
                            'key': '4366'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Bolt Pattern:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.BoltPattern)) : null, wheelData.HubBore ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_hubbore',
                            'key': '4677'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hub Bore:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.HubBore)) : null, wheelData.Hardware ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_hardware',
                            'key': '4971'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hardware:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Hardware)) : null, wheelData.Thread ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_thread',
                            'key': '5268'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Thread:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Thread)) : null, wheelData.Hex ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_hex',
                            'key': '5557'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hex:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Hex)) : null, wheelData.TPMS ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_tpms',
                            'key': '5834'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'TPMS:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.TPMS)) : null, wheelData.SeatType ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_seat-type',
                            'key': '6115'
                        }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Seat Type:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.SeatType)) : null)) : null];
                }
                function repeatTireSize2(tireSizes, tireSize, tireSizeIndex) {
                    return _createElement('span', {
                        'className': 'cm_wheel-tire-vehicle-info_tire-sizes__item',
                        'key': tireSize
                    }, '\n                ', tireSize, '\n              ');
                }
                function scopeTireSizes3() {
                    var tireSizes = this.data.TireSizes;
                    return [tireSizes?.length > 0 ? _createElement('div', {
                            'className': 'cm_wheel-tire-vehicle-info_tires-data',
                            'key': '64611'
                        }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment' }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment__label' }, 'Recommended Tire Sizes')), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_tire-sizes__container' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cm_wheel-tire-vehicle-info_tire-sizes' },
                            _map(tireSizes, repeatTireSize2.bind(this, tireSizes))
                        ]))) : null];
                }
                return window.innerWidth > 999 ? _createElement('div', {
                    'className': 'cm_wheel-tire-vehicle-info cm_WheelTireVehicleInfo',
                    'key': '0'
                }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                    'className': 'cm_wheel-tire-vehicle-info_header cm_collapsible_header facettitle',
                    'onClick': this.toggleCollapsed
                }, '\n    Vehicle Wheel/Tire Info\n    ', this.collapsed ? [_createElement('svg', {
                        'className': 'cm_icon cm_icon-angle left',
                        'height': '20px',
                        'role': 'img',
                        'viewBox': '39 30 565 565',
                        'aria-hidden': 'true',
                        'key': '2730'
                    }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.collapsed ? [_createElement('svg', {
                        'className': 'cm_icon cm_icon-angle down',
                        'height': '20px',
                        'role': 'img',
                        'viewBox': '39 30 565 565',
                        'aria-hidden': 'true',
                        'key': '5920'
                    }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', { 'className': 'cm_collapsible-elem facetbody' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_inner-container' }, [this.autoSyncVisualization(function () {
                        return _createElement('div', { 'className': 'cm_autoSyncVisualization' }, _createElement('div', { 'className': 'cm_vehicle-visualization-container' }, this.visualization));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_title' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_title-vehicle' }, this.vehicleString)), scopeWheelData1.apply(this, []), scopeTireSizes3.apply(this, []))))) : null;
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FilterChips(function () {
                return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function scopeInchMm1_rangeChip() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '0 0 22 22',
                                                'key': '7210'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '2570'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'a',
                                        {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        },
                                        _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                    ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '4900'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                            'div',
                            {
                                'role': 'list',
                                'className': 'cmRepeater_chips'
                            },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'cm_btn cm_btn button button--small button--secondary cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Start over\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FacetBarWheels(function () {
                function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry4(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3683'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3885'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                    'className': this.extraClassName,
                                    'key': '980'
                                }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '149'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', '', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '8901'
                                        }, 'Popular Makes'),
                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '8904'
                                        }, 'All Makes'),
                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                    ] : null,
                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2979'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', '', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                ]) : null)]));
                        }, { count: undefined })];
                }
                function scopeFacet_bar_label2() {
                    var facet_bar_label = 'Shop Wheels';
                    return _createElement('div', { 'className': 'cm_FacetBarWheels' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title facettitle' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_btn button button--small button--secondary',
                        'onClick': this.discardFields,
                        'disabled': !this.hasSelection,
                        'role': 'button',
                        'tabIndex': this.hasSelection ? '0' : '-1'
                    }, [_createElement('svg', {
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'className': 'cm_icon cm_icon-reset',
                            'height': '16px',
                            'viewBox': '0 0 16 16',
                            'key': '68490'
                        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
                }
                return scopeFacet_bar_label2.apply(this, []);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FacetBarTires(function () {
                function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry4(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3683'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3885'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                    'className': this.extraClassName,
                                    'key': '980'
                                }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '149'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', '', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '8901'
                                        }, 'Popular Makes'),
                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '8904'
                                        }, 'All Makes'),
                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                    ] : null,
                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2979'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', '', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                ]) : null)]));
                        }, { count: undefined })];
                }
                function scopeFacet_bar_label2() {
                    var facet_bar_label = 'Shop Tires';
                    return _createElement('div', { 'className': 'cm_FacetBarTires' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title facettitle' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_btn button button--small button--secondary',
                        'onClick': this.discardFields,
                        'disabled': !this.hasSelection,
                        'role': 'button',
                        'tabIndex': this.hasSelection ? '0' : '-1'
                    }, [_createElement('svg', {
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'className': 'cm_icon cm_icon-reset',
                            'height': '16px',
                            'viewBox': '0 0 16 16',
                            'key': '68470'
                        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
                }
                return scopeFacet_bar_label2.apply(this, []);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FacetPanel(function () {
                return _createElement('div', { 'className': 'cm_FacetPanel' }, [this.facets(function () {
                        function repeatFacets1(facets, facetsIndex) {
                            return [facets(function () {
                                    function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', { 'className': 'facetentrykey' }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues2(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', { 'className': 'facetentrykey' }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues3(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), !this.isCustomRange ? [
                                                    _createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '1541'
                                                    }, _createElement('span', {}, this.value)),
                                                    _createElement('div', {
                                                        'className': 'facetentryval',
                                                        'key': '1543'
                                                    }, this.hitCount)
                                                ] : null, this.isCustomRange ? [_createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '3901'
                                                    }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                            }, { count: undefined })];
                                    }
                                    function scopeInchMm4() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', {
                                            'className': 'cmTemplate_sliderFacet',
                                            'key': '12518'
                                        }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Min',
                                                                'className': 'cm_inputMin'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                    'className': 'separator',
                                                    'key': '334'
                                                }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Max',
                                                                'className': 'cm_inputMax'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'cm_btn cm_btn button button--small button--secondary',
                                                    'onClick': this.setCustomRange
                                                }, '\n    GO\n  '));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]);
                                    }
                                    function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues6(Values, ValuesIndex) {
                                        return [Values(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? [
                                        _createElement('div', {
                                            'className': 'facettitle',
                                            'data-cm-role': 'toggle-facet',
                                            'tabIndex': '0',
                                            'key': '561'
                                        }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-angle left',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '39 30 565 565',
                                                'aria-hidden': 'true',
                                                'key': '2380'
                                            }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-angle down',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '39 30 565 565',
                                                'aria-hidden': 'true',
                                                'key': '5590'
                                            }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null),
                                        _createElement('div', {
                                            'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                            'key': '563'
                                        }, this.template === 'simpleFacet' ? _createElement('div', {
                                            'role': 'list',
                                            'className': 'cmTemplate_simpleFacet',
                                            'key': '1073'
                                        }, this.showFilterInput ? _createElement('div', {
                                            'className': 'filter-input',
                                            'key': '1171'
                                        }, [this.filterInput(function () {
                                                return _createElement('div', {
                                                    'className': 'input  cm_filterInput',
                                                    'placeholder': 'Enter'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], this.inputNotEmpty ? _createElement('span', {
                                            'className': 'filter-input_clear-container',
                                            'onClick': this.clearInput,
                                            'key': '1491'
                                        }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                                'className': 'facetdiv',
                                                'key': '16601'
                                            }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues overflowed' }, _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_ShowAlwaysValues' },
                                            _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                        ]), _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_Values' },
                                            _map(this.Values, repeatValues2.bind(this))
                                        ]), this.needShowMore ? _createElement('div', {
                                            'className': 'facetdiv cm_show-all-container',
                                            'key': '3251'
                                        }, _createElement('a', {
                                            'className': 'cm_show-all',
                                            'data-cm-role': 'toggle-show-more'
                                        }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                            'className': 'cmTemplate_selectFacet',
                                            'key': '3566'
                                        }, [this.select(function () {
                                                function repeatEntry1(entry, entryIndex) {
                                                    return _createElement('option', {
                                                        'disabled': this.loading,
                                                        'value': entry.term,
                                                        'key': entry.value
                                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                                }
                                                function repeatEntry2(entry, entryIndex) {
                                                    return _createElement('option', {
                                                        'disabled': this.loading,
                                                        'value': entry.term,
                                                        'key': entry.value
                                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                                }
                                                function repeatEntry3(entry, entryIndex) {
                                                    return _createElement('option', {
                                                        'disabled': this.loading,
                                                        'value': entry.term,
                                                        'key': entry.value
                                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                                }
                                                function repeatEntry4(entry, index) {
                                                    return _createElement('div', {
                                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                        'key': entry.value,
                                                        'onClick': () => this.onChange(entry.term)
                                                    }, this.showCheckboxes ? _createElement('input', {
                                                        'type': 'checkbox',
                                                        'readOnly': true,
                                                        'checked': entry.selected,
                                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                        'key': '3596'
                                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                        'className': 'cm_dropdown_hitCount',
                                                        'key': '3798'
                                                    }, entry.hitCount) : null);
                                                }
                                                return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                    'key': '62'
                                                }, _createElement('select', {
                                                    'className': 'cm_select_inner-select cm_select__pretty',
                                                    'onChange': this.onChange,
                                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                    'disabled': this.disabled,
                                                    'aria-label': this.title
                                                }, this.useNativeDropdown ? [
                                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                        'value': '',
                                                        'key': 'null-option'
                                                    }, '\n          ', '', this.title, '\n        ') : null,
                                                    this.loading ? _createElement('option', {
                                                        'key': 'loading-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '8031'
                                                        }, 'Popular Makes'),
                                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '8034'
                                                        }, 'All Makes'),
                                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                                    ] : null,
                                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                                    'key': '_current',
                                                    'value': '_current'
                                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                    'div',
                                                    {
                                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                        'aria-label': this.title,
                                                        'key': '2892'
                                                    },
                                                    !this.hideNullOption ? _createElement('div', {
                                                        'className': 'option',
                                                        'key': 'null-option',
                                                        'onClick': () => this.onChange('')
                                                    }, '\n      ', '', this.title, '\n    ') : null,
                                                    this.loading ? _createElement('div', {
                                                        'className': 'option',
                                                        'key': 'null-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                                ]) : null));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                            'role': 'list',
                                            'className': 'cmTemplate_priceFacet',
                                            'key': '9548'
                                        }, !this.ranges.length ? [
                                            _createElement('div', { 'key': '96441' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                            '\n  ',
                                            this.slider,
                                            '\n  ',
                                            _createElement('div', {
                                                'className': 'cm_flex',
                                                'key': '96443'
                                            }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                                        ] : null, this.ranges.length ? [_createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cmRepeater_Values',
                                                    'key': '99081'
                                                },
                                                _map(this.Values, repeatValues3.bind(this))
                                            ])] : null, [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Min price',
                                                            'className': 'cm_inputMin'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Max price',
                                                            'className': 'cm_inputMax'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })], _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'cm_btn cm_btn button button--small button--secondary',
                                                    'onClick': this.setCustomRange
                                                }, '\n    GO\n  '));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]) : null, this.template === 'sliderFacet' ? scopeInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                            'role': 'list',
                                            'className': 'cmTemplate_reviewFacet',
                                            'key': '14302'
                                        }, _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_ShowAlwaysValues' },
                                            _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                        ]), _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_Values' },
                                            _map(this.Values, repeatValues6.bind(this))
                                        ])) : null)
                                    ] : null, this.template === 'toggleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_toggleFacet',
                                        'key': '17462'
                                    }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                        'className': 'cm_facet-toggle_input',
                                        'type': 'checkbox',
                                        'checked': this.isToggled,
                                        'onClick': this.toggleFacet
                                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_facets' },
                            _map(this.facets, repeatFacets1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })])], _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t contain any\n    ',
                !!this.vehicleString && !this.notFilteredByVehicle ? [
                    '\n      products that fit a ',
                    _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                '\n    Click ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '32301'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3749'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3968'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4212'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '43630' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '44500' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '45330' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '43284' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.VehicleLabel_search(function () {
            return window.location.pathname === '/search' ? _createElement('div', {
                'className': 'cm_vehicle-widget cm_vehicle-widget__page-title cm_VehicleLabel_search',
                'key': '0'
            }, _createElement('h1', { 'className': 'collection__title h1 heading' }, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label-container cmTemplate_locked',
                'key': '185'
            }, _createElement('span', { 'className': 'cm_vehicle-widget_title' }, this.fitmentSearchTitle, ' '), _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, 'for the ', this.vehicleString)) : null, this.template !== 'locked' ? [_createElement('span', {
                    'className': 'cm_vehicle-widget_title',
                    'key': '4731'
                }, this.fitmentSearchTitle)] : null)) : null;
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.WheelTireVehicleInfo(function () {
            function scopeWheelData1() {
                var wheelData = this.data.WheelData;
                return [wheelData ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_wheels-data',
                        'key': '15341'
                    }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment' }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment__label' }, 'Recommended Wheel Sizes')), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_axle_container' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_front-axle' }, wheelData.RearAxle ? _createElement('span', {
                        'className': 'cm_wheel-tire-vehicle-info_axle-title',
                        'key': '2013'
                    }, 'Front Sizes:') : null, wheelData.FrontAxle.Diameter ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '2126'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Diameter:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Diameter)) : null, wheelData.FrontAxle.Width ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '2452'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Width:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Width)) : null, wheelData.FrontAxle.Offset ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '2769'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Offset:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Offset)) : null), wheelData.RearAxle ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_rear-axle',
                        'key': '3106'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_axle-title' }, 'Rear Sizes:'), wheelData.RearAxle.Diameter ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '3283'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Diameter:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Diameter)) : null, wheelData.RearAxle.Width ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '3607'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Width:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Width)) : null, wheelData.RearAxle.Offset ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '3922'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Offset:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Offset)) : null) : null), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_attributes_container' }, wheelData.BoltPattern ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_bolt-pattern',
                        'key': '4347'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Bolt Pattern:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.BoltPattern)) : null, wheelData.HubBore ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_hubbore',
                        'key': '4658'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hub Bore:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.HubBore)) : null, wheelData.Hardware ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_hardware',
                        'key': '4952'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hardware:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Hardware)) : null, wheelData.Thread ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_thread',
                        'key': '5249'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Thread:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Thread)) : null, wheelData.Hex ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_hex',
                        'key': '5538'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hex:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Hex)) : null, wheelData.TPMS ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_tpms',
                        'key': '5815'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'TPMS:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.TPMS)) : null, wheelData.SeatType ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_seat-type',
                        'key': '6096'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Seat Type:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.SeatType)) : null)) : null];
            }
            function repeatTireSize2(tireSizes, tireSize, tireSizeIndex) {
                return _createElement('span', {
                    'className': 'cm_wheel-tire-vehicle-info_tire-sizes__item',
                    'key': tireSize
                }, '\n                ', tireSize, '\n              ');
            }
            function scopeTireSizes3() {
                var tireSizes = this.data.TireSizes;
                return [tireSizes?.length > 0 ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_tires-data',
                        'key': '64421'
                    }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment' }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment__label' }, 'Recommended Tire Sizes')), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_tire-sizes__container' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_wheel-tire-vehicle-info_tire-sizes' },
                        _map(tireSizes, repeatTireSize2.bind(this, tireSizes))
                    ]))) : null];
            }
            return _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info cm_desktop-hide cm_WheelTireVehicleInfo' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                'className': 'cm_wheel-tire-vehicle-info_header cm_collapsible_header facettitle',
                'onClick': this.toggleCollapsed
            }, '\n    Vehicle Wheel/Tire Info\n    ', this.collapsed ? [_createElement('svg', {
                    'className': 'cm_icon cm_icon-angle left',
                    'height': '20px',
                    'role': 'img',
                    'viewBox': '39 30 565 565',
                    'aria-hidden': 'true',
                    'key': '2540'
                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.collapsed ? [_createElement('svg', {
                    'className': 'cm_icon cm_icon-angle down',
                    'height': '20px',
                    'role': 'img',
                    'viewBox': '39 30 565 565',
                    'aria-hidden': 'true',
                    'key': '5730'
                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', { 'className': 'cm_collapsible-elem facetbody' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_inner-container' }, [this.autoSyncVisualization(function () {
                    return _createElement('div', { 'className': 'cm_autoSyncVisualization' }, _createElement('div', { 'className': 'cm_vehicle-visualization-container' }, this.visualization));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_title' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_title-vehicle' }, this.vehicleString)), scopeWheelData1.apply(this, []), scopeTireSizes3.apply(this, [])))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.UpsizeDownsizeFacetPanel(function () {
            return this.facets.length === 1 && this.isVehicleSelected ? _createElement('div', {
                'className': 'cm_upsize-downsize cm_UpsizeDownsizeFacetPanel',
                'key': '0'
            }, [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'cm_button ' + (this.isSelected ? 'cm_selected cm_btn button button--small button--primary' : 'cm_btn button button--small button--secondary') }, '\n              ', this.value, '\n            ');
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'cm_field-' + this.field }, _createElement('div', { 'className': 'cm_upsize-downsize-facettitle' }, this.name), _createElement('div', { 'className': 'cm_upsize-downsize-facetbody' }, this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '165'
                                }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cm_button-container cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ])) : null));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]) : null;
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'cm_SearchHeader-container' }, [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, _createElement('div', { 'className': 'cm_total-hits' }, _createElement('span', {}, this.totalHits, ' results')), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.FacetDialogButton(function () {
            return _createElement('div', { 'className': 'cm_facetDialogButton  cm_FacetDialogButton' }, _createElement('div', { 'className': 'cm_btn button button--small button--primary' }, 'Filters \n    ', this.withSelection ? [_createElement('svg', {
                    'className': 'cm_icon cm_filter-icon',
                    'viewBox': '-5 0 394 394.00003',
                    'key': '1340'
                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                    'className': 'cm_icon cm_filter-icon',
                    'viewBox': '0 0 247.46 247.46',
                    'key': '8750'
                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]), [this.FacetDialogButton(function () {
            return _createElement('div', { 'className': 'cm_facetDialogButton cm_facetDialogButton__sticky  cm_FacetDialogButton' }, _createElement('div', { 'className': 'cm_btn button button--small button--primary' }, 'FILTER BY SIZE'));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        return _createElement('div', { 'className': 'product-item product-item--vertical 1/3--tablet-and-up 1/4--desk' }, this.on_sale ? _createElement('div', {
                            'className': 'product-item__label-list',
                            'key': '81'
                        }, _createElement('span', { 'className': 'product-label product-label--on-sale' }, '\n      Save ', this.formatPrice(this.compare_at_price - this.price), '\n    ')) : null, _createElement('a', {
                            'href': this.url,
                            'className': 'product-item__image-wrapper' + (this.image2 ? ' product-item__image-wrapper--with-secondary' : '')
                        }, _createElement('div', {
                            'className': 'aspect-ratio aspect-ratio--square',
                            'style': { paddingBottom: '100%' }
                        }, _createElement('img', {
                            'className': 'product-item__primary-image image--fade-in lazyautosizes lazyloaded',
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError
                        }), this.image2 ? _createElement('img', {
                            'className': 'product-item__secondary-image image--fade-in lazyautosizes lazyloaded',
                            'src': this.imageOrDefault(this.resizeImage(this.image2)),
                            'alt': this.removeHTML(this.title),
                            'onError': e => this.onImageError(e, 'image2'),
                            'key': '734'
                        }) : null)), _createElement('div', { 'className': 'product-item__info' }, _createElement('div', { 'className': 'product-item__info-inner' }, _createElement('a', mergeProps({
                            'className': 'product-item__vendor link',
                            'href': this.vendor_url
                        }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('a', mergeProps({
                            'href': this.url,
                            'className': 'product-item__title text--strong link'
                        }, { dangerouslySetInnerHTML: { __html: this.breakWordOnSlash(this.title) } })), _createElement('div', { 'className': 'product-item__price-list price-list' }, this.on_sale ? [
                            _createElement('div', {
                                'className': this.show_set_price ? 'price-container ' : '',
                                'key': '14441'
                            }, _createElement('span', { 'className': 'price price--highlight' }, this.formatPrice(this.price)), _createElement('span', { 'className': 'price price--compare' }, this.formatPrice(this.compare_at_price)), _createElement('span', { 'className': 'price-label' }, '\n              ', this.FitmentType.some(f => f === 'Tire' || f === 'Wheel') ? `per
              ${ this.FitmentType.includes('Tire') ? 'tire' : 'wheel' }` : 'each', '\n            ')),
                            this.show_set_price ? _createElement('div', {
                                'className': 'price-container',
                                'key': '14443'
                            }, _createElement('span', { 'className': 'price price--highlight' }, this.formatPrice(this.price * 4)), _createElement('span', { 'className': 'price price--compare' }, this.formatPrice(this.compare_at_price * 4)), _createElement('span', { 'className': 'price-label' }, 'set of 4')) : null
                        ] : null, !this.on_sale ? [
                            _createElement('div', {
                                'className': this.show_set_price ? 'price-container ' : '',
                                'key': '24181'
                            }, _createElement('span', { 'className': 'price' }, this.formatPrice(this.price)), _createElement('span', { 'className': 'price-label' }, '\n              ', this.FitmentType.some(f => f === 'Tire' || f === 'Wheel') ? `per
              ${ this.FitmentType.includes('Tire') ? 'tire' : 'wheel' }` : 'each', '\n            ')),
                            this.show_set_price ? _createElement('div', {
                                'className': 'price-container',
                                'key': '24183'
                            }, _createElement('span', { 'className': 'price' }, this.formatPrice(this.price * 4)), _createElement('span', { 'className': 'price-label' }, 'set of 4')) : null
                        ] : null), this.inventory_quantity && this.inventory_quantity >= 5 ? _createElement('span', {
                            'className': 'product-item__inventory inventory inventory--high',
                            'key': '3174'
                        }, '\n        IN STOCK - ', this.inventory_quantity >= 16 ? '16+' : `${ this.inventory_quantity }`, '\n      ') : null, this.inventory_quantity && this.inventory_quantity < 5 ? _createElement('span', {
                            'className': 'product-item__inventory inventory inventory--low',
                            'key': '3442'
                        }, '\n        Only ', this.inventory_quantity, ' units left\n      ') : null, this.price ? _createElement('p', {
                            'className': 'affirm-as-low-as',
                            'data-amount': this.price * 100,
                            'data-affirm-type': 'logo',
                            'data-affirm-color': 'blue',
                            'key': '3650'
                        }) : null), _createElement('form', {
                            'method': 'post',
                            'action': '/cart/add',
                            'acceptCharset': 'UTF-8',
                            'className': 'product-item__action-list button-stack',
                            'encType': 'multipart/form-data'
                        }, _createElement('input', {
                            'type': 'hidden',
                            'name': 'quantity',
                            'value': '1'
                        }), _createElement('input', {
                            'type': 'hidden',
                            'name': 'id',
                            'value': this.variant_ids
                        }), this.out_of_stock ? _createElement('button', {
                            'className': 'product-item__action-button button button--small button--disabled',
                            'disabled': true,
                            'key': '4063'
                        }, '\n        Sold out\n      ') : null, !this.out_of_stock ? _createElement('button', {
                            'type': 'submit',
                            'className': 'product-item__action-button product-item__action-button--list-view-only button button--small button--primary',
                            'data-action': 'add-to-cart',
                            'key': '4223'
                        }, '\n        Add to cart\n      ') : null, _createElement('button', {
                            'type': 'button',
                            'className': 'product-item__action-button product-item__action-button--list-view-only button button--small button--ternary hidden-phone',
                            'data-action': 'open-modal',
                            'data-secondary-action': 'open-quick-view',
                            'aria-controls': 'modal-quick-view-search-template',
                            'data-product-url': this.url
                        }, '\n        Quick view\n      '))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                { 'className': 'product-list product-list--collection product-list--with-sidebar card' + (this.view ? ` ${ this.view }` : '') + ' cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '7050'
                        }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '10350'
                        }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1568'
                    }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1982'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2236'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2554'
                    }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t contain any\n    ',
                        !!this.vehicleString && !this.notFilteredByVehicle ? [
                            '\n      products that fit a ',
                            _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                        '\n    Click ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '32301'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3749'
                    }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3968'
                    }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '4212'
                    }, 'Your search request failed to process\n', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '43630' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '44500' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '45330' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '43284' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse cm_btn button button--small button--primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        ', window.Convermax.config?.loadMoreText || `Next ${ this.nextPageSize }`, '\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })])));
}
        export const componentNames = ["cm:autoSyncVisualization","cm:WheelTireVehicleInfo","cm:filterChips","cm:FilterChips","cm:filterInput","cm:FacetBarWheels","cm:filterInput","cm:FacetBarTires","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:FacetPanel","cm:message","cm:customMessage","cm:VehicleLabel_search","cm:autoSyncVisualization","cm:WheelTireVehicleInfo","cm:facets","cm:UpsizeDownsizeFacetPanel","cm:SearchHeader","cm:FacetDialogButton","cm:FacetDialogButton","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult"]